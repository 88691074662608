import ApplicationElement from "./application_element";

export default class FilterDropdownElement extends ApplicationElement {
  static tagName = "filter-dropdown";
  static refs = ["button", "dropdown"];
  static events = {
    click: [
      { method: "toggle", ref: "button" },
      { method: "closeIfClickedOutside", element: document },
    ],
    change: [{ method: "submit", ref: "dropdown" }],
    keyup: [{ method: "closeOnEscape" }],
  };

  afterConnected() {
    this.setSelected();
  }

  toggle(e) {
    e.preventDefault();
    this.toggleAttribute("open");
  }

  get open() {
    return this.hasAttribute("open");
  }

  get submitButton() {
    return this.closest("form").querySelector(
      "input[type=submit],button[type=submit]",
    );
  }

  closeIfClickedOutside(e) {
    if (!this.open) return;
    // (de)selecting an option will programmatically trigger a click event via submit().
    // Since the target of this click is outside the element, we filter these out.
    if (this.submitButton === e.target) return;
    if (this.contains(e.target)) return;

    this.close();
  }

  close() {
    this.removeAttribute("open");
  }

  closeOnEscape(e) {
    if (this.open) return;

    if (e.key === "Escape") {
      this.close();
    }
  }

  submit() {
    this.setSelected();
    this.parentNode.querySelector("#filter-submit").click();
  }

  setSelected() {
    if (this.refs.dropdown.querySelectorAll("input:checked").length > 0) {
      this.setAttribute("selected", true);
    } else {
      this.removeAttribute("selected");
    }
  }
}
