import "../stylesheets/index.css";
import "@hotwired/turbo-rails";
import {
  AutosubmitForm,
  CharacterCounterElement,
  ClickableCardElement,
  ContentValidatorElement,
  FilterDropdownElement,
  NewRegistrationElement,
  OpenDialogElement,
  OtherOptionElement,
  PasswordRevealElement,
  PasswordValidatorElement,
  FieldValidatorElement,
  TextareaAutoresizeElement,
  UnreadNotificationElement,
} from "../elements";

AutosubmitForm.register();
CharacterCounterElement.register();
ClickableCardElement.register();
ContentValidatorElement.register();
FilterDropdownElement.register();
NewRegistrationElement.register();
OpenDialogElement.register();
OtherOptionElement.register();
PasswordRevealElement.register();
PasswordValidatorElement.register();
FieldValidatorElement.register();
TextareaAutoresizeElement.register();
UnreadNotificationElement.register();

window.addEventListener("DOMContentLoaded", () =>
  scrollToDetails(window.location),
);

window.addEventListener("turbo:load", (event) =>
  scrollToDetails(event.detail.url),
);

function scrollToDetails(url) {
  if (typeof url === "string") url = new URL(url);
  if (!url.hash) return;

  const hash = url.hash;
  const target = document.querySelector(hash);
  if (!target) return;

  const details = target.closest("details");
  if (details) details.open = true;

  target.scrollIntoView({
    behavior: "smooth",
  });
}
