import ApplicationElement from "./application_element";

export default class FieldValidatorElement extends ApplicationElement {
  static tagName = "field-validator";
  static refs = ["error"];
  static events = {
    input: { method: "validate" },
  };

  validate(e) {
    if (!this.refs.error) return;
    switch (this.refs.error.dataset.fieldValidatorErrorType) {
      case "blank":
        this.validateRequiredField(e);
        break;
    }
  }

  validateRequiredField(e) {
    // If the input is a checkbox, radio button, or select element, check if any of the options are selected
    if (this.querySelector("input[type=checkbox],input[type=radio]")) {
      if (this.querySelectorAll("input:checked").length > 0) {
        this.hideErrorMessage();
      } else {
        this.showErrorMessage();
      }
      return;
    }
    // If the input is a text input, check if the input is empty
    if (e.target.value !== "") {
      this.hideErrorMessage();
    } else {
      this.showErrorMessage();
    }
  }

  hideErrorMessage() {
    this.refs.error.hidden = true;
    this.classList.remove("input--invalid");
  }

  showErrorMessage() {
    this.refs.error.hidden = false;
    this.classList.add("input--invalid");
  }
}
