import ApplicationElement from "./application_element";

export default class NewRegistrationElement extends ApplicationElement {
  static tagName = "new-registration";
  static refs = ["output"];
  static events = {
    input: { method: "update", immediate: true },
  };

  update() {
    if (this.active === "false") return;

    const newMunicipalities = this.querySelectorAll(
      'input:checked[data-new-registration="true"]',
    );

    if (newMunicipalities.length > 0) {
      const content = this.template.replace(
        "{{municipalities}}",
        [...newMunicipalities]
          .map(
            (element) =>
              this.querySelector(`label[for="${element.id}"]`).textContent,
          )
          .join(", "),
      );
      this.refs.output.textContent = content;
      this.refs.output.hidden = false;
    } else {
      this.refs.output.hidden = true;
    }
  }

  get template() {
    return this.dataset.newRegistrationTemplate;
  }
}
